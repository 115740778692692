import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseHitsProps, useHits } from 'react-instantsearch-hooks-web';
import useGlobalData from '@hooks/useGlobalData';
import HitsToggle from '../HitsToggle/HitsToggle';
import Hit, { ResultType } from '../Hit/Hit';
import { HitsItemList, HitsWrapper } from './Hits.styles';

function Hits(props: UseHitsProps) {
  const [isVisible, setIsVisible] = useState(true);
  const { hits, results, sendEvent } = useHits(props);
  const { getInternalLink } = useGlobalData();

  useEffect(() => {
    // NOTE Display hits when typing a different query
    if (results?.query) {
      setIsVisible(true);
    }
  }, [results?.query]);

  const handleResultClick = useCallback(
    (hit: ResultType) => {
      sendEvent('click', hit, 'Clicked on a hit');
    },
    [sendEvent]
  );

  const hitComponents = useMemo(
    () =>
      (hits as ResultType[])
        .filter((hit) => !hit.options?.split(',').includes('is_page_hidden'))
        .map((hit) => {
          const linkUrl = getInternalLink({ codename: hit.codename });
          return (
            <li key={hit.objectID}>
              <Hit handleResultClick={handleResultClick} hit={hit} url={linkUrl} />
            </li>
          );
        }),
    [getInternalLink, handleResultClick, hits]
  );

  if (!results?.query) {
    return null;
  }

  return (
    <HitsWrapper>
      <HitsToggle
        hitsNumber={hits.length}
        isVisible={isVisible}
        handleClick={() => setIsVisible((prev) => !prev)}
      />
      {isVisible && <HitsItemList>{hitComponents}</HitsItemList>}
    </HitsWrapper>
  );
}

export default Hits;
